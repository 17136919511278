import React from "react"
import classNames from "classnames"
import "./index.css"

export default ({ width, padding, children }) => {
  const widthClass = width ? "is-width-" + width : ""
  const paddingClass = padding ? "is-padding-" + padding : ""
  const sectionInnerClasses = classNames(
    "section-inner",
    widthClass,
    paddingClass
  )
  return (
    <section className="section">
      <div className={sectionInnerClasses}>{children}</div>
    </section>
  )
}
