import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import "./index.css"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query AppMenu {
          wpgraphql {
            generalSettings {
              url
            }
            menus(where: { location: MENU_HEADER_MAIN }) {
              edges {
                node {
                  menuItems {
                    edges {
                      node {
                        label
                        url
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const devUrl = data.wpgraphql.generalSettings.url
        const menuItems = data.wpgraphql.menus.edges[0].node.menuItems
        return (
          <section className="app-menu">
            <nav className="app-menu-nav">
              <ul>
                {menuItems.edges.map((edge, i) => (
                  <li key={i}>
                    {(() => {
                      const url = edge.node.url
                      if (url === "/") {
                        return (
                          <Link to={url} activeClassName="is-active">
                            {edge.node.label}
                          </Link>
                        )
                      } else if (url.match(devUrl)) {
                        const parseUrl = new URL(url)
                        const parseUrlPathname = parseUrl.pathname
                        return (
                          <Link
                            to={parseUrlPathname}
                            activeClassName="is-active"
                            partiallyActive={true}
                          >
                            {edge.node.label}
                          </Link>
                        )
                      } else {
                        return (
                          <Link
                            to={url}
                            activeClassName="is-active"
                            partiallyActive={true}
                          >
                            {edge.node.label}
                          </Link>
                        )
                      }
                    })()}
                  </li>
                ))}
              </ul>
            </nav>
          </section>
        )
      }}
    />
  )
}
