import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./index.css"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query AppFooter {
          wpgraphql {
            appOptions {
              global {
                startYear
                copylight
                copylightUrl
              }
            }
          }
        }
      `}
      render={(data) => {
        const global = data.wpgraphql.appOptions.global
        const nowYear = new Date().getFullYear()
        return (
          <footer className="app-footer">
            <div className="app-footer-contents">
              {global.copylight && (
                <p className="app-footer-copylight">
                  <span>©</span>&nbsp;
                  {global.copylightUrl ? (
                    <a
                      href={global.copylightUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {global.copylight}
                    </a>
                  ) : (
                    global.copylight
                  )}
                  &nbsp;
                  <span>
                    {global.startYear && <>2016 - </>}
                    {nowYear}
                  </span>
                </p>
              )}
            </div>
          </footer>
        )
      }}
    />
  )
}
