import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Logo from "../../../assets/svg/logo.svg"
import "./index.css"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query AppHeader {
          wpgraphql {
            appOptions {
              global {
                title
                description
                twitterId
              }
            }
          }
        }
      `}
      render={(data) => {
        const global = data.wpgraphql.appOptions.global
        return (
          <header className="app-header">
            <div className="app-header-contents">
              <Link className="app-header-logo-wrap" to="/">
                <Logo className="app-header-logo" title={global.title} />
              </Link>
              <p className="app-header-description">{global.description}</p>
              {global.twitterId && (
                <nav className="app-header-buttons">
                  <ul>
                    <li>
                      <a
                        href={"https://twitter.com/" + global.twitterId}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="icon"
                          icon={["fab", "twitter"]}
                        />
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </header>
        )
      }}
    />
  )
}
