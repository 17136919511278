import React from "react"
import AppHeader from "./header"
import AppMenu from "./menu"
import AppFooter from "./footer"
import AppMain from "./main"
import "../../assets/css/variable.css"
import "../../assets/css/base.css"
import "./index.css"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faHistory,
  faArrowRight,
  faCheck,
  faAsterisk,
  faTimes,
  faAngleRight,
  faAngleDoubleRight,
  faArrowAltCircleRight,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleRight,
  faHandPointRight,
  faExclamationTriangle,
  faExclamationCircle,
  faEdit,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons"
import {
  faCalendarAlt,
  faArrowAltCircleRight as farArrowAltCircleRight,
} from "@fortawesome/free-regular-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

library.add(
  faHistory,
  faArrowRight,
  faCheck,
  faAsterisk,
  faTimes,
  faAngleRight,
  faAngleDoubleRight,
  faArrowAltCircleRight,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleRight,
  faHandPointRight,
  faExclamationTriangle,
  faExclamationCircle,
  faEdit,
  faLightbulb,
  faCalendarAlt,
  farArrowAltCircleRight,
  faTwitter
)

export default ({ children }) => {
  return (
    <div className="apps">
      <AppHeader />
      <AppMenu />
      <AppMain>{children}</AppMain>
      <AppFooter />
    </div>
  )
}
